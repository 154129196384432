/**
 * @typedef {Object<string, string>} actionNames HashMap of constants used for action names. (e.g: INIT_APP)
 */
const actionNames = {
  INIT_APP: "INIT_APP",
  SEAMLESS_NAVIGATE: "SEAMLESS_NAVIGATE",
  LAYOUT_MOUNT: "LAYOUT_MOUNT",
  START_NAVIGATE: "START_NAVIGATE",
  ACCOUNT_CREATED: "ACCOUNT_CREATED",
  PRINT: "PRINT",
  FILTER_ITEMS: "FILTER_ITEMS",
  SIGN_IN: "SIGN_IN",
  SIGNED_IN: "SIGNED_IN",
}

export default actionNames
