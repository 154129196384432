import uaParser from "ua-parser-js"
import { isBrowser } from "../../helpers/browser"
import { isLoggedIn } from "../API/profile-service"
import config from "./config"
import { handleAction } from "./scenarios"

import {
  getDeviceType,
  getTimeSinceLastVisit,
  initializeKatAnalytics,
} from "@kroger/behavioral-analytics-devmarketplace"

// import {
//   getBrowserName,
//   getBrowserVersion,
//   getConnectionType,
//   getDeviceModel,
//   getLocationStatus,
//   getOSVersion,
//   getOperatingSystemName,
//   getSDKVersion,
// } from "@kroger/behavioral-analytics-devmarketplace"

const getLocalProfileId = () => {
  try {
    return JSON.parse(localStorage.getItem("profile")).v.id
  } catch (e) {
    console.warn(e)
  }
}
const getPID = () => {
  try {
    return document.cookie
      .split(";")
      .find(c => c.indexOf("pid") !== -1)
      .split("=")[1]
  } catch (e) {
    console.warn(e)
    return ""
  }
}

/**
 * @singleton Metrics
 */
class Metrics {
  /**
   * Dispatch action for metrics processing, consumed by kroger-usage-metrics library.
   * @param {String} actionName name of action
   * @param {Object<string, any>} payload Object sent as the payload
   * @returns {void}
   */
  static send(actionName, payload) {
    if (!isBrowser()) return

    if (process.env.GATSBY_ANALYTICS_ENABLED === "false") return
    if (!Metrics.initialized) Metrics.initialize()
    window.krogerUsageMetrics.vanillaUsageMetrics.processActivity(
      actionName,
      payload
    )

    // send to kroger analytics
    handleAction(actionName, payload)
  }
  static initialize() {
    if (isBrowser()) {
      const browserInfo = new uaParser().getResult()
      window.krogerUsageMetrics.vanillaUsageMetrics({
        metaDataHook: () => {
          return {
            bannerName: "developer marketplace",
            loginState: isLoggedIn(),
            pid: "pid",
          }
        },
        transforms: config.transforms,
        /* configUrl and actionUrl are actually used to configure the route to send to metrics, not what they imply */
        configUrl: config.clickstreamUrl,
        actionUrl: config.clickstreamUrl,
      })

      // currently GATSBY_METRICS_V1_DISABLED is not set
      initializeKatAnalytics({
        clickstreamUrl: config.v1ClickstreamUrl,
        appName: "devmarketplace",
        appVersion: 2,
        appId: "devmarketplace-2",
        allowLocation: "not asked",
        bannerName: "kroger",

        metaDataHook: () => {
          return {
            browserHeight: window.innerHeight,
            browserWidth: window.innerWidth,
            browserType: browserInfo.browser.name,
            browserVersion: browserInfo.browser.version,
            connectionType: "unknown",
            buildType: "customer release",
            deviceModel: browserInfo.device.model,
            deviceOS: browserInfo.os.name + " " + browserInfo.os.version,
            devicePlatform: browserInfo.os.name,
            deviceType: getDeviceType(),
            environment: process.env.GATSBY_METRICS_ENV,
            guid: isLoggedIn() && getLocalProfileId(),
            pid: getPID(),
            referrer: document.referrer,
            source: "dev marketplace",
            timeSinceLaunch: Date.now() - window.launchTime,
            timeSinceLastVisit: getTimeSinceLastVisit(),
            timezone: new Date().getTimezoneOffset() / -60,
            timestamp: Date.now(),
            timestampISO8601: new Date().toISOString(),
            url: window.location.href,
            userAgent: browserInfo.ua,
            userRole: isLoggedIn() ? "active" : "unauthenticated",
            loginState: isLoggedIn(),
            visitorID: "",
          }
        },
      })

      Metrics.initialized = true
      window.Metrics = Metrics
      window.dispatchEvent(new CustomEvent("metrics-ready"))
    }
  }
}

export default Metrics
