/* eslint-disable no-restricted-globals */
import {
  buildAccountCreated,
  buildCompleteFlow,
  buildContinueFlow,
  buildCreateAccountStart,
  buildFilterItemsSearch,
  buildInitApp,
  buildPrint,
  buildSendEmail,
  buildSignInStart,
  buildSignedIn,
  buildStartFlow,
  buildStartNavigate,
  recordScenario,
} from "@kroger/behavioral-analytics-devmarketplace"

import actionNames from "./actionNames"

// functions on this object are purposely kebab-cased to match the literal scenario name as done before.

/**
 * v1Scenarios
 */
const v1Scenarios = {
  /** Scenario: Init App
   *  @param {[actionNames.INIT_APP|actionNames.SEAMLESS_NAVIGATE]} actionName action name that triggered this scenario
   *  @param {Object.<string, any>} payload key/value pair object from the action
   */
  "init-app": (actionName, payload) => {
    recordScenario(
      buildInitApp({
        pageName: payload.componentName || "Home",
      })
    )

    // FYI: The v0 init-app had some code around SEAMLESS_NAVIGATE, but further investigation showed it was not being used anywhere in the app, also
    // it didn't make sense, as it was calling it only if there was an error on the page, I'll leave the old version commented out in here incase we need it

    // name: "init-app",
    // modifiers: {
    //   [actionNames.INIT_APP]: ({ scenarioData, action: { payload } }) => {
    //     return {
    //       trigger: true,
    //       scenarioData: scenarioData.set(
    //         "componentName",
    //         payload.componentName || "Home"
    //       ),
    //     }
    //   },
    //   [actionNames.SEAMLESS_NAVIGATE]: ({
    //     scenarioData,
    //     action: { payload },
    //   }) =>
    //     !new URLSearchParams(window.location.search).has("error")
    //       ? { trigger: false, scenarioData }
    //       : {
    //           trigger: true,
    //           scenarioData: scenarioData
    //             .set("componentName", payload.componentName)
    //             .set("usageContext", "navigate_click"),
    //         },
    // },
  },

  /** Scenario: Start Navigate
   *  @param {[actionNames.START_NAVIGATE]} actionName action name that triggered this scenario
   *  @param {Object.<string, any>} payload key/value pair object from the action
   */
  "start-navigate": (actionName, payload) => {
    // by using a separate object for scenariodata, we can prevent adding a property called "itemIndex" to the scenario
    const scenarioData = {}
    if (typeof payload.itemIndex === "number") {
      scenarioData.itemIndex = payload.itemIndex
    }

    recordScenario(
      buildStartNavigate({
        componentName: payload.componentName || "navigation",
        usageContext: payload.usageContext || "navigation",
        // this way we only add the itemIndex if it exists, as we do not want to send an itemIndex with an undefined or null value in cases
        // where there is no itemIndex
        ...scenarioData,
      })
    )
  },

  /** Scenario: Create Account Start
   *  @param {[actionNames.LAYOUT_MOUNT]} actionName action name that triggered this scenario
   *  @param {Object.<string, any>} payload key/value pair object from the action
   */
  "create-account-start": (actionName, payload) => {
    if (sessionStorage.getItem("create-account") === "header") {
      sessionStorage.removeItem("create-account")
      recordScenario(
        buildCreateAccountStart({
          componentName: "header",
          // usageContext: "create account",
        })
      )
    }
  },

  /** Scenario: Account Created
   *  @param {[actionNames.LAYOUT_MOUNT]} actionName action name that triggered this scenario
   *  @param {Object.<string, any>} payload key/value pair object from the action
   */
  "account-created": (actionName, payload) => {
    if (payload.componentName === "/create-account/verify-email") {
      recordScenario(
        buildAccountCreated({
          accountSource: "traditional",
          // @todo - request usage of "verify email"
          componentName: "verify email",
        })
      )
    }
  },

  /** Scenario: Send Email
   *  @param {[actionNames.LAYOUT_MOUNT]} actionName action name that triggered this scenario
   *  @param {Object.<string, any>} payload key/value pair object from the action
   */
  "send-email": (actionName, payload) => {
    if (sessionStorage.getItem("emailVerification") === "complete") {
      sessionStorage.removeItem("emailVerification")
      recordScenario(
        buildSendEmail({
          componentName: "email confirm",
          // usageContext: "send email",
        })
      )
    }
    if (sessionStorage.getItem("forgotEmailSent") === "complete") {
      sessionStorage.removeItem("forgotEmailSent")
      recordScenario(
        buildSendEmail({
          componentName: "forgot password",
          // usageContext: "send email",
        })
      )
    }
  },

  /** Scenario: Start Flow
   *  @param {[actionNames.LAYOUT_MOUNT]} actionName action name that triggered this scenario
   *  @param {Object.<string, any>} payload key/value pair object from the action
   */
  "start-flow": (actionName, payload) => {
    if (sessionStorage.getItem("register-app") === "apps") {
      sessionStorage.removeItem("register-app")
      recordScenario(
        buildStartFlow({
          componentName: "applications",
          // usageContext: "start flow",
        })
      )
    }
    if (sessionStorage.getItem("register-app") === "homepage") {
      sessionStorage.removeItem("register-app")
      recordScenario(
        buildStartFlow({
          componentName: "step cards",
        })
      )
    }
  },

  /** Scenario: Continue Flow
   *  @param {[actionNames.LAYOUT_MOUNT]} actionName action name that triggered this scenario
   *  @param {Object.<string, any>} payload key/value pair object from the action
   */
  "continue-flow": (actionName, payload) => {
    if (sessionStorage.getItem("register-app") === "continue") {
      sessionStorage.removeItem("register-app")
      recordScenario(
        buildContinueFlow({
          componentName: "register applications",
          // usageContext: "continue flow",
        })
      )
    }
  },

  /** Scenario: Complete Flow
   *  @param {[actionNames.LAYOUT_MOUNT]} actionName action name that triggered this scenario
   *  @param {Object.<string, any>} payload key/value pair object from the action
   */
  "complete-flow": (actionName, payload) => {
    if (sessionStorage.getItem("register-app") === "complete") {
      sessionStorage.removeItem("register-app")
      recordScenario(
        buildCompleteFlow({
          componentName: "registration details",
          pageName: "Application Details",
          // usageContext: "complete flow",
        })
      )
    }
  },

  /** Scenario: Print
   *  @param {[actionNames.PRINT]} actionName action name that triggered this scenario
   *  @param {Object.<string, any>} payload key/value pair object from the action
   */
  print: (actionName, payload) => {
    recordScenario(
      buildPrint({
        // @todo petition for allowing any componentName to be used, as we have print being used in multiple areas and will surely grow, also has nothing to do with kroger.com banner print
        componentName: payload.componentName,
        pageName: payload.componentName,
      })
    )
  },
  /** Scenario: Filter Items
   *  @param {[actionNames.FILTER_ITEMS]} actionName action name that triggered this scenario
   *  @param {Object.<string, any>} payload key/value pair object from the action
   */
  "filter-items": (actionName, payload) => {
    recordScenario(
      buildFilterItemsSearch({
        componentName: payload.componentName,
        search: payload.searchTerm,
        searchResultsTotal: payload.searchResultsTotal,
      })
    )
  },

  /** Scenario: Sign In Start
   *  @param {[actionNames.SIGN_IN]} actionName action name that triggered this scenario
   *  @param {Object.<string, any>} payload key/value pair object from the action
   */
  "sign-in-start": (actionName, payload) => {
    recordScenario(
      buildSignInStart({
        componentName: "dev mktplace signin",
      })
    )
  },

  /** Scenario: Signed In
   *  @param {[actionNames.SIGNED_IN]} actionName action name that triggered this scenario
   *  @param {Object.<string, any>} payload key/value pair object from the action
   */
  "signed-in": (actionName, payload) => {
    recordScenario(
      buildSignedIn({
        componentName: "dev mktplace signin",
        authenticationMethod: "single sign on",
        linkSocialLogin: false,
      })
    )
  },
}

// This should always remain as simply a hash where keys are action names and values are the list of scenarios that get called whenever these functions execute.
// This way, we can easily see which scenarios are triggered by each action, even though some scenarios will short-circuit in some cases.
// "When these actions are triggered, consider these scenarios"
const actionScenarioMap = {
  [actionNames.FILTER_ITEMS]: ["filter-items"],
  [actionNames.INIT_APP]: ["init-app"],
  [actionNames.LAYOUT_MOUNT]: [
    "create-account-start",
    "account-created",
    "send-email",
    "start-flow",
    "continue-flow",
    "complete-flow",
  ],
  [actionNames.PRINT]: ["print"],
  [actionNames.SEAMLESS_NAVIGATE]: ["init-app"],
  [actionNames.SIGNED_IN]: ["signed-in"],
  [actionNames.SIGN_IN]: ["sign-in-start"],
  [actionNames.START_NAVIGATE]: ["start-navigate"],
}

// DEPRECATED
const scenarios = [
  {
    name: "start-navigate",
    modifiers: {
      [actionNames.START_NAVIGATE]: ({ scenarioData, action: { payload } }) => {
        if (typeof payload.itemIndex === "number") {
          scenarioData.set("itemIndex", payload.itemIndex)
        }

        return {
          trigger: true,
          scenarioData: scenarioData
            .set("componentName", payload.componentName || "navigation")
            .set("usageContext", payload.usageContext || "navigation")
            .set("destination", payload.destination),
        }
      },
    },
  },
  {
    name: "account-created",
    modifiers: {
      [actionNames.LAYOUT_MOUNT]: ({ scenarioData, action: { payload } }) => {
        if (payload.componentName === "/create-account/verify-email") {
          return {
            trigger: true,
            scenarioData: scenarioData
              .set("componentName", "verify email")
              .set("usageContext", "account created"),
          }
        }
      },
    },
  },
  {
    name: "init-app",
    modifiers: {
      [actionNames.INIT_APP]: ({ scenarioData, action: { payload } }) => {
        return {
          trigger: true,
          scenarioData: scenarioData.set(
            "componentName",
            payload.componentName || "Home"
          ),
        }
      },
      [actionNames.SEAMLESS_NAVIGATE]: ({
        scenarioData,
        action: { payload },
      }) =>
        !new URLSearchParams(window.location.search).has("error")
          ? { trigger: false, scenarioData }
          : {
              trigger: true,
              scenarioData: scenarioData
                .set("componentName", payload.componentName)
                .set("usageContext", "navigate_click"),
            },
    },
  },
  {
    name: "create-account-start",
    modifiers: {
      [actionNames.LAYOUT_MOUNT]: ({ scenarioData, action: { payload } }) => {
        if (sessionStorage.getItem("create-account") === "header") {
          sessionStorage.removeItem("create-account")
          return {
            trigger: true,
            scenarioData: scenarioData
              .set("componentName", "header")
              .set("usageContext", "create account"),
          }
        } else {
          return {
            trigger: false,
            scenarioData: scenarioData,
          }
        }
      },
    },
  },
  {
    name: "send-email",
    modifiers: {
      [actionNames.LAYOUT_MOUNT]: ({ scenarioData, action: { payload } }) => {
        if (sessionStorage.getItem("emailVerification") === "complete") {
          sessionStorage.removeItem("emailVerification")
          return {
            trigger: true,
            scenarioData: scenarioData
              .set("componentName", "email confirm")
              .set("usageContext", "send email"),
          }
        }
        if (sessionStorage.getItem("forgotEmailSent") === "complete") {
          sessionStorage.removeItem("forgotEmailSent")
          return {
            trigger: true,
            scenarioData: scenarioData
              .set("componentName", "forgot password")
              .set("usageContext", "send email"),
          }
        } else {
          return {
            trigger: false,
            scenarioData: scenarioData,
          }
        }
      },
    },
  },
  {
    name: "start-flow",
    modifiers: {
      [actionNames.LAYOUT_MOUNT]: ({ scenarioData, action: { payload } }) => {
        if (sessionStorage.getItem("register-app") === "apps") {
          sessionStorage.removeItem("register-app")
          return {
            trigger: true,
            scenarioData: scenarioData
              .set("componentName", "applications")
              .set("usageContext", "start flow"),
          }
        } else if (sessionStorage.getItem("register-app") === "homepage") {
          sessionStorage.removeItem("register-app")
          return {
            trigger: true,
            scenarioData: scenarioData
              .set("componentName", "step cards")
              .set("usageContext", "start flow"),
          }
        } else {
          return {
            trigger: false,
            scenarioData: scenarioData,
          }
        }
      },
    },
  },
  {
    name: "continue-flow",
    modifiers: {
      [actionNames.LAYOUT_MOUNT]: ({ scenarioData, action: { payload } }) => {
        if (sessionStorage.getItem("register-app") === "continue") {
          sessionStorage.removeItem("register-app")
          return {
            trigger: true,
            scenarioData: scenarioData
              .set("componentName", "register applications")
              .set("usageContext", "continue flow"),
          }
        } else {
          return {
            trigger: false,
            scenarioData: scenarioData,
          }
        }
      },
    },
  },
  {
    name: "complete-flow",
    modifiers: {
      [actionNames.LAYOUT_MOUNT]: ({ scenarioData, action: { payload } }) => {
        if (sessionStorage.getItem("register-app") === "complete") {
          sessionStorage.removeItem("register-app")
          return {
            trigger: true,
            scenarioData: scenarioData
              .set("componentName", "registration details")
              .set("usageContext", "complete flow"),
          }
        } else {
          return {
            trigger: false,
            scenarioData: scenarioData,
          }
        }
      },
    },
  },
  {
    name: "print",
    modifiers: {
      [actionNames.PRINT]: ({ scenarioData, action: { payload } }) => {
        return {
          trigger: true,
          scenarioData: scenarioData
            .set("componentName", payload.componentName)
            .set("usageContext", "print"),
        }
      },
    },
  },
  {
    name: "filter-items",
    modifiers: {
      [actionNames.FILTER_ITEMS]: ({ scenarioData, action: { payload } }) => ({
        trigger: true,
        scenarioData: scenarioData
          .set("componentName", payload.componentName)
          .set("usageContext", payload.usageContext)
          .set("searchTerm", payload.searchTerm)
          .set("searchResultsTotal", payload.searchResultsTotal),
      }),
    },
  },
  {
    name: "sign-in-start",
    modifiers: {
      [actionNames.SIGN_IN]: ({ scenarioData }) => ({
        trigger: true,
        scenarioData: scenarioData
          .set("componentName", "dev mktplace signin")
          .set("usageContext", "sign in"),
      }),
    },
  },
  {
    name: "signed-in",
    modifiers: {
      [actionNames.SIGNED_IN]: ({ scenarioData }) => ({
        trigger: true,
        scenarioData: scenarioData
          .set("componentName", "dev mktplace signin")
          .set("usageContext", "successfully signed in")
          .set("authenticationMethod", "single sign on"),
      }),
    },
  },
]

export const handleAction = (actionName, payload = {}) => {
  if (!actionScenarioMap[actionName]) {
    console.warn(
      actionName,
      "(v1 analytics) is not a viable action for metrics collection, operation canceled"
    )
    return
  }
  try {
    actionScenarioMap[actionName].forEach(fnName =>
      v1Scenarios[fnName](actionName, payload)
    )

    const sendToGa = () => {
      //make sure gtag is available on the window
      if (window.gtag) {
        if (payload.usageContext === "page_load") {
          // don't need to send page_load events separately to GA
          return
        }
        switch (actionName) {
          case "FILTER_ITEMS": {
            // not sure .searchTerm is ever present - currently use url params and GA4's "view_search_results"
            // if this is a search event, wrap it like GA wants for searches
            window.gtag("event", "search", {
              search_term: payload.searchTerm,
            })
            break
          }
          case "SIGNED_IN": {
            //if this is a sign in event then wrap it like GA wants for login
            window.gtag("event", "login", {
              method: "single sign on",
            })
            break
          }
          case "PRINT": {
            window.gtag("event", "print", {
              event_category: payload.componentName,
            })
            break
          }
          case "START_NAVIGATE": {
            window.gtag("event", "start-navigate", {
              event_category: payload.componentName,
              event_label: payload.usageContext,
            })
            break
          }
          default: {
            if (payload.usageContext !== undefined) {
              window.gtag("event", actionScenarioMap[actionName][0], {
                event_category: payload.componentName,
                event_label: payload.usageContext,
              })
            }
          }
        }
      }
    }
    setTimeout(sendToGa, 100)
  } catch (e) {
    console.warn(e)
    console.warn(
      "(v1 analytics) scenario triggered by action:",
      actionName,
      "failed",
      payload
    )
  }
}

export default scenarios
