import scenarios from "./scenarios"

const usageMetricsConfig = {
  clickstreamUrl: process.env.GATSBY_API_ROOT + "/v1/metrics/usage/actions",
  v1ClickstreamUrl: process.env.GATSBY_API_ROOT + "/v1/metrics/usage",
  transforms: {
    transformVersion: `developer-marketplace`,
    scenarios,
  },
}

export default usageMetricsConfig
