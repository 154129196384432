export const buildUrl = (path, href = window.location.href) =>
  new URL("/" + path, new URL(href)).toString()

export const buildRedirectUrl = (path, href = window.location.href) =>
  new URL("/" + path + "?redirect=" + new URL(href), new URL(href)).toString()

export const buildSignoutUrl = () => buildRedirectUrl("signout")

export const signinRedirect = () => {
  const winHash = window.location.hash ? window.location.hash : ""
  window.sessionStorage.setItem(
    "location",
    `${window.location.pathname}${window.location.search}${winHash}`
  )
  window.location.href = buildUrl("sso-redirect")
}

export const signinRedirectSessionStorage = state => {
  const winHash = window.location.hash ? window.location.hash : ""
  window.sessionStorage.setItem(
    "location",
    `${window.location.pathname}${window.location.search}${winHash}`
  )

  window.sessionStorage.setItem(state, true)

  window.location.href = buildUrl("sso-redirect")
}

export const accessDeniedRedirect = () => {
  window.location.href = buildUrl("access-denied")
}

//add to browser history with a search term
export const addHistoryWithSearch = term => {
  if (term) {
    window.history.pushState(
      {},
      null,
      `${window.location.pathname}?search=${term}`
    )
  }
}
export const replaceHistoryWithSearch = term => {
  if (term) {
    window.history.replaceState(
      {},
      null,
      `${window.location.pathname}?search=${term}`
    )
  } else {
    window.history.replaceState(
      {},
      null,
      `${window.location.pathname}`
    )
  }
}

export const refreshRedirect = redirectUrl => {
  if (redirectUrl) {
    window.location.href = `${process.env.GATSBY_UI_DOMAIN}${redirectUrl}`
  } else {
    window.location.href = process.env.GATSBY_UI_DOMAIN
  }
}
