/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("whatwg-fetch")
require("element-scroll-polyfill")
const { invokeSilentTokenRequest } = require("./src/helpers/auth")
const { refreshRedirect } = require("./src/helpers/url")
const {
  defineCustomElements,
} = require("@kroger/kds-web-components/dist/loader")
const { LocalStorage } = require("ttl-localstorage")
const Msal = require("@azure/msal-browser")
const SwaggerUI = require("swagger-ui")

const setDynatraceUserTag = () => {
  if (typeof window !== "undefined") {
    if (!window.dt) {
      window.dt = {
        // could also be anonymous but we actually don't know if they are anonymous or not at this point
        userTag: "unknown",
      }
    }
    try {
      const profile = LocalStorage.getSync("profile")
      if (profile) {
        window.dt.userTag = profile.internal ? "internal" : "external"
      }
    } catch (e) {
      console.warn(e)
    }
  }
}

// normally we want to execute code onClientEntry, but dynatrace starts collecting data before, so if localstorage is already set we can collect more accurate data
setDynatraceUserTag()

const maybeSetPreviewSession = () => {
  try {
    if (window.location.hostname === "developer.kroger.com") return // not in prod!
    const urlParams = new window.URLSearchParams(window.location.search)
    if (urlParams.has("preview")) {
      window.sessionStorage.docPreview = urlParams.get("preview")
      window.sessionStorage.docPreviewError = ""
    }
  } catch (e) {
    console.error(e)
    if (e.message) {
      window.sessionStorage.docPreviewError = e.message
    } else {
      window.sessionStorage.docPreviewError =
        "cannot use preview, make sure it is a proper url with protocol."
    }
  }
}

exports.onClientEntry = async () => {
  // Dynatrace will always use the latest user tag when providing session data, but not individual actions.  While we will set it earier when gatsby-browser.js first loads, this will ensure it is loaded on every page.
  // we just need to also call it earlier as well as now just in case the localstorage data is there, that way we can have more accurate analytics.
  setDynatraceUserTag()
  maybeSetPreviewSession()

  //Preview Feature for Doc Content Repo
  //wrap in try catch to avoid breaking the site if something goes wrong.

  //SwaggerUI
  window.SwaggerUI = SwaggerUI

  // Authentication
  const config = {
    auth: {
      clientId: process.env.GATSBY_AZURE_B2C_CLIENTID,
      redirectUri: `${process.env.GATSBY_UI_DOMAIN}/sso-spa-landing`,
      authority: process.env.GATSBY_AZURE_B2C_AUTHORITY,
      postLogoutRedirectUri: process.env.GATSBY_UI_DOMAIN,
      knownAuthorities: [process.env.GATSBY_AZURE_B2C_DOMAIN],
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: "localStorage",
    },
  }
  defineCustomElements(window)
  window.launchTime = Date.now()

  // If we are on developer-ce.kroger.com, and we've been redirected from an ephemeral envrionemnt, then state query param will exist
  // we'll need to set the state to localstorage so we can redirect later.
  if (
    window.location.hostname === "developer-ce.kroger.com" &&
    window.location.search.includes("branchId")
  ) {
    const branchId = window.location.search.split("?branchId=")[1]
    if (branchId) {
      LocalStorage.putSync("branchId", branchId)
    }
  }

  window.myMsalClient = new Msal.PublicClientApplication(config)

  window.addEventListener("metrics-ready", function () {
    window.Metrics.send("INIT_APP", {
      componentName: document.title || "developer-marketplace",
    })
  })
  document.addEventListener(
    "click",
    function (event) {
      try {
        if (event.target.matches(".document-post a")) {
          const metricsPayload = {
            componentName: "DocumentContent",
            usageContext: event.target.innerText,
            destination: event.target.href,
          }
          window.Metrics &&
            window.Metrics.send("START_NAVIGATE", metricsPayload)
        }
      } catch (e) {
        // dont let errors break application, just log them
        console.error(e)
      }
    },
    false
  )

  document.addEventListener("click", function (event) {
    try {
      if (event.target.matches(".document-post .view-the-rationale")) {
        window.dispatchEvent(
          new CustomEvent("document-content:view-rationale", {
            detail: event.target.nextSibling.innerHTML,
          })
        )
      }
    } catch (e) {
      console.error(e)
      console.error(
        "documentation content rationale error, please check the markdown!"
      )
    }
  })
}

exports.onPreRouteUpdate = async () => {
  if (process.env.GATSBY_OAUTH2_STATE) return
  if (
    window.location.pathname === "/sso-spa-landing/" ||
    window.location.pathname === "/sso-spa-landing"
  )
    return
  // Extra security measure, we only want to redirect if the domain is developer-ce.kroger.com
  if (window.location.hostname === "developer-ce.kroger.com") {
    const branchId = LocalStorage.getSync("branchId")
    if (branchId) {
      const jwt = LocalStorage.getSync("accessToken")
      if (jwt) {
        LocalStorage.removeKeySync("branchId")
        LocalStorage.removeKeySync("accessToken")
        LocalStorage.removeKeySync("profile")
        LocalStorage.removeKeySync("checkLogin")
        const ephemeraldomain = `https://${branchId}.apidmp-dev.kpsazc.dgtl.kroger.com/sso-spa-landing?jwt=${jwt}`
        window.location.href = ephemeraldomain
      }
      return // reaturn to prevent executing any other code
    }
  }
  const accessTokenResponse = await invokeSilentTokenRequest()

  if (accessTokenResponse) {
    await LocalStorage.put("accessToken", accessTokenResponse.accessToken)

    if (window.sessionStorage.getItem("location")) {
      const savedLocation = window.sessionStorage.getItem("location")
      window.sessionStorage.removeItem("location")
      refreshRedirect(savedLocation)
    } else {
      refreshRedirect(
        `${window.location.pathname}${window.location.search}${window.location.hash}`
      )
    }
  }
}

exports.onRouteUpdate = () => {
  // just incase localstorage.profile wasn't available earlier, last chance to get user tag.
  setDynatraceUserTag()
}
