import Metrics, { actionNames } from "../services/metrics"
import { LocalStorage } from "ttl-localstorage"
import { refreshRedirect } from "./url"
import { isLoggedIn } from "../services/API/profile-service"

export const invokeAzureSignInRedirect = async (ssoRedirect = false, e) => {
  //stop the link from processing the href which we need on the link to not break validation
  e && e.preventDefault()

  // Set SessionStorage with the URL we need to have the user back
  if (!ssoRedirect) {
    window.sessionStorage.setItem(
      "location",
      `${window.location.pathname}${window.location.search}${window.location.hash}`
    )
  }

  // If we're on an ephemeral environment, we'll need to redirect the user to developer-ce.kroger.com
  // without invoking MSAL.
  if (process.env.GATSBY_OAUTH2_STATE) {
    window.location.href =
      "https://developer-ce.kroger.com/sso-redirect?branchId=" +
      process.env.GATSBY_OAUTH2_STATE
    return
  }

  const loginRequest = {
    scopes: [
      process.env.GATSBY_AZURE_B2C_CLIENTID,
      "profile",
      "openid",
      "offline_access",
    ],
    prompt: "login",
    nonce: "default_nonce",
    policy: process.env.GATSBY_AZURE_B2C_POLICY,
  }

  Metrics.send(actionNames.SIGN_IN)

  window.myMsalClient.loginRedirect(loginRequest)
}

export const invokeAzureSignOutRedirect = async () => {
  await LocalStorage.removeKey("checkLogin")
  await LocalStorage.removeKey("accessToken")
  await LocalStorage.removeKey("profile")

  const accountId = LocalStorage.getSync("accountId")

  const logoutRequest = {
    account: accountId,
  }

  window.myMsalClient.logoutRedirect(logoutRequest)
}

export const invokeSilentTokenRequest = () => {
  return new Promise(function (resolve, reject) {
    if (!isLoggedIn()) return resolve()
    const loginRequest = {
      scopes: [
        process.env.GATSBY_AZURE_B2C_CLIENTID,
        "profile",
        "openid",
        "offline_access",
      ],
      prompt: "login",
      nonce: "default_nonce",
      policy: process.env.GATSBY_AZURE_B2C_POLICY,
    }

    const accountId = LocalStorage.getSync("accountId")

    loginRequest.account = window.myMsalClient.getAccountByHomeId(accountId)

    window.myMsalClient
      .acquireTokenSilent(loginRequest)
      .then(async function (accessTokenResponse) {
        // In case the response from B2C server has an empty accessToken field
        // throw an error to initiate token acquisition
        if (accessTokenResponse.accessToken === "") {
          await LocalStorage.removeKey("checkLogin")
          await LocalStorage.removeKey("accessToken")
          await LocalStorage.removeKey("profile")
          invokeAzureSignInRedirect()
        } else {
          if (
            accessTokenResponse.accessToken ===
            LocalStorage.getSync("accessToken")
          ) {
            return resolve()
          }
          return resolve(accessTokenResponse)
        }
      })
      .catch(async error => {
        console.error(
          "Silent token acquisition fails. Acquiring token using signIn. \n",
          error
        )
        await LocalStorage.removeKey("checkLogin")
        await LocalStorage.removeKey("accessToken")
        await LocalStorage.removeKey("profile")
        window.sessionStorage.setItem(
          "location",
          `${window.location.pathname}${window.location.search}${window.location.hash}`
        )
        window.myMsalClient.acquireTokenRedirect(loginRequest)
      })
  })
}
