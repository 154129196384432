export const isBrowser = () => typeof window !== "undefined"

export const copyToClipboard = str => {
  const el = document.createElement("textarea")
  el.value = str
  document.body.appendChild(el)
  el.select()
  document.execCommand("copy")
  document.body.removeChild(el)
}

export const scrollToTop = () => {
  document.querySelector("#main-content").scrollIntoView({
    behavior: "smooth",
  })
}
