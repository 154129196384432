/* eslint-disable no-restricted-globals */
import deepmerge from "deepmerge"
import { getReasonPhrase } from "http-status-codes"
import { join as joinPath } from "path"
import { LocalStorage } from "ttl-localstorage"
import { isBrowser } from "../../helpers/browser"
import { retrieveIdToken } from "../../helpers/businessLogic/retrieve-id-token"
const maybeAddAuthHeader = () =>
  retrieveIdToken()
    ? {
        Authorization: "Bearer " + retrieveIdToken(),
      }
    : {}

const defaultOptions = {
  baseEndpoint: new URL(
    (process.env.GATSBY_API_ROOT || "http://localhost") + "/v1/developer"
  ),
  noCredentials: false,
}

export class APIService {
  constructor(options = defaultOptions) {
    this.apiEndpointBase = options.baseEndpoint
      ? new URL(options.baseEndpoint)
      : new URL(
          (process.env.GATSBY_API_ROOT || "http://localhost") + "/v1/developer"
        )
    this.request = this.request.bind(this)
    this.getResponseBody = this.getResponseBody.bind(this)
    this.noCredentials = options.noCredentials
    /* so we dont clutter the class with the same function body for all of the methods */
    const methods = [
      "get",
      "head",
      "post",
      "put",
      "delete",
      "connect",
      "options",
      "trace",
      "patch",
    ]
    methods.forEach(method => {
      this[method] = (path = "", fetchOptions = {}, baseEndPoint) => {
        return this.request({
          method: method.toUpperCase(),
          path,
          fetchOptions,
          baseEndPoint,
        })
      }
    })
  }

  get ttl() {
    return LocalStorage.timeoutInSeconds
  }

  set ttl(value) {
    LocalStorage.timeoutInSeconds = value
  }

  async getResponseBody(response) {
    try {
      const text = await response.text()
      const data = JSON.parse(text)
      return data
    } catch (err) {
      return {}
    }
  }

  async request(
    { method, path, fetchOptions = {}, baseEndPoint },
    retry = true
  ) {
    if (!isBrowser) return
    if (process && process.env && process.env.NODE_ENV === "test") return

    const handleHttpError = (targetPath, statusCode, correlationId) => {
      // An HTTP Error occurred when requesting developer.kroger.com/api/v1/profile/me. 413 Request Entity Too Large. Correlation ID: ############-####
      const prefix = "An HTTP Error occurred when requesting "

      const errorMessage =
        prefix +
        `${targetPath}. ` +
        `${statusCode} ` +
        getReasonPhrase(statusCode) +
        (correlationId ? `. Correlation ID: ${correlationId}` : ".")

      console.error(errorMessage)
      const err = new Error()
      err.message = errorMessage
      err.statusCode = statusCode
      throw err
    }
    const handleError = (
      err,
      abort = false,
      responseBody = null,
      responseStatus = null
    ) => {
      if (abort) {
        if (err instanceof Error) throw err
        const errorObject = new Error(err)
        errorObject.errors = responseBody.errors
        if (responseStatus) {
          errorObject.responseStatus = responseStatus
        }
        if (
          responseBody &&
          responseBody.errors &&
          responseBody.errors.rootCauses &&
          Array.isArray(responseBody.errors.rootCauses) &&
          responseBody.errors.rootCauses[0] &&
          responseBody.errors.rootCauses[0].code &&
          responseBody.errors.rootCauses[0].reason &&
          responseBody.errors.rootCauses[0].code === "JwtValidationException" &&
          responseBody.errors.rootCauses[0].reason.startsWith("Jwt expired")
        ) {
          errorObject.jwtExpired = true
        } else if (
          responseBody &&
          responseBody.error &&
          responseBody.error_description &&
          responseBody.error === "invalid_token" &&
          responseBody.error_description ===
            "The access_token is invalid or has expired"
        ) {
          errorObject.jwtExpired = true
        }
        throw errorObject
      }
      if (retry) {
        return this.request({ method, path, fetchOptions, baseEndPoint }, false)
      }
      if (err instanceof Error) throw err
      const errorObject = new Error(err)
      errorObject.errors = responseBody.errors
      if (
        responseBody &&
        responseBody.errors &&
        responseBody.errors.rootCauses &&
        Array.isArray(responseBody.errors.rootCauses) &&
        responseBody.errors.rootCauses[0] &&
        responseBody.errors.rootCauses[0].code &&
        responseBody.errors.rootCauses[0].reason &&
        responseBody.errors.rootCauses[0].code === "JwtValidationException" &&
        responseBody.errors.rootCauses[0].reason.startsWith("Jwt expired")
      ) {
        errorObject.jwtExpired = true
      } else if (
        responseBody &&
        responseBody.error &&
        responseBody.error_description &&
        responseBody.error === "invalid_token" &&
        responseBody.error_description ===
          "The access_token is invalid or has expired"
      ) {
        errorObject.jwtExpired = true
      }
      throw errorObject
    }

    let uri = null
    if (baseEndPoint && baseEndPoint !== "unset") {
      uri = new URL(joinPath(baseEndPoint, path)).toString()
    } else {
      const { pathname, origin } = this.apiEndpointBase
      uri = new URL(joinPath(pathname, path), origin).toString()
    }

    try {
      const fetchOptionsWithAuth = this.noCredentials
        ? fetchOptions
        : deepmerge({ headers: maybeAddAuthHeader() }, fetchOptions)

      const response = await fetch(
        uri,
        Object.assign(
          {},
          {
            method,
            cache: "no-cache",
            credentials: "include",
          },
          fetchOptionsWithAuth
        )
      )

      const responseBody = await this.getResponseBody(response)

      if (response.status > 399) {
        const errors = responseBody.errors

        // if errors object is missing, then the error has occurred downstream from our service.
        // fallback to show the HTTP error.
        if (!errors) {
          handleHttpError(
            uri || path,
            response.status,
            response.headers.get("x-correlation-id")
          )
        }

        const reason =
          errors.reason || (errors.length > 0 && errors[0]?.reason) || "Error"

        const getRootCauses = () => {
          const causesList =
            errors.rootCauses || (errors.length > 0 && errors[0]?.rootCauses)
          return (
            Array.isArray(causesList) && causesList.map(({ reason }) => reason)
          )
        }

        const rootCauses = getRootCauses()

        const errorMessage = rootCauses
          ? `${reason}, ${rootCauses.join("\n")}`
          : `${reason}`
        return handleError(errorMessage, true, responseBody, response.status)
      }
      return responseBody
    } catch (err) {
      return handleError(err, false)
    }
  }
}

export default new APIService()
