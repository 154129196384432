/* eslint-disable no-restricted-globals */
import api from "./APIService"
import { LocalStorage } from "ttl-localstorage"
import { isBrowser } from "../../helpers/browser"
import { invokeSilentTokenRequest } from "../../helpers/auth"
import { refreshRedirect } from "../../helpers/url"

const withJson = data => ({
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify(data),
})

/**
 * Attempts to get user profile data from local storage first,
 * then attempt a fetch if 'checkLogin' key exists on localstorage.
 * checkLogin will only exist if user visits the sign in page, and remains unless an error occurs.
 * @param {Boolean} forceRefresh - force the client to update from the server instead from localstorage.
 * @returns {Promise<Profile>}
 */
export const getLoggedInProfile = async (
  forceRefresh = false,
  componentHandleErrorOverride
) => {
  if (!isBrowser()) return
  if (!window.myMsalClient) return
  if (!forceRefresh) {
    const accessToken = await LocalStorage.get("accessToken")
    if (!accessToken) {
      try {
        const response = await window.myMsalClient.handleRedirectPromise()
        if (response) {
          LocalStorage.put("checkLogin", true)
          LocalStorage.put("accessToken", response.accessToken)
          LocalStorage.put("accountId", response.account.homeAccountId)
        }

        // Extra security measure, we only want to redirect if the domain is developer-ce.kroger.com
        if (window.location.hostname === "developer-ce.kroger.com") {
          const branchId = LocalStorage.getSync("branchId")
          if (branchId) {
            const jwt = LocalStorage.getSync("accessToken")
            if (jwt) {
              const ephemeraldomain = `https://${branchId}.apidmp-dev.kpsazc.dgtl.kroger.com/sso-spa-landing?jwt=${jwt}`
              window.location.href = ephemeraldomain
            }
            return // reaturn to prevent executing any other code
          }
        }
      } catch (e) {
        console.error("Handle Redirect Promise failed", e)
        throw e
      }
    }

    const profile = await LocalStorage.get("profile")

    if (profile) return profile

    const attemptFetch = await LocalStorage.keyExists("checkLogin")

    if (!attemptFetch) return
  }

  const onError =
    typeof componentHandleErrorOverride === "function"
      ? componentHandleErrorOverride
      : async err => {
          const accessTokenResponse = await invokeSilentTokenRequest()

          if (accessTokenResponse) {
            await LocalStorage.put(
              "accessToken",
              accessTokenResponse.accessToken
            )
            window.sessionStorage.setItem(
              "location",
              `${window.location.pathname}${window.location.search}${window.location.hash}`
            )
            refreshRedirect(
              `${window.location.pathname}${window.location.search}${window.location.hash}`
            )
          } else {
            await LocalStorage.removeKey("accessToken")
            await LocalStorage.removeKey("checkLogin")
            await LocalStorage.removeKey("profile")
            await LocalStorage.removeKey("branchId")

            if (!window.location.pathname.includes("sso-spa-landing")) {
              refreshRedirect(
                `${window.location.pathname}${window.location.search}`
              )
            }
            console.error(err)
          }
        }

  const onSuccess = responseModel => {
    if (
      responseModel &&
      responseModel.data &&
      Array.isArray(responseModel.data.developers) &&
      responseModel.data.developers.length
    ) {
      LocalStorage.put("profile", responseModel.data.developers[0])
      return responseModel.data.developers[0]
    } else {
      return onError("response does not contain data.developers[0]")
    }
  }

  try {
    const result = await api.get("/profile/me")
    return onSuccess(result)
  } catch (err) {
    return onError(err)
  }
}

/**
 * Retrieve member profile details
 *
 */
export const getProfileDetails = async id => {
  const response = await api.get(`/profile/${id}`)

  return response.data.developers[0]
}

/**
 * We logged in?
 * True if we are using browser and are logged in, otherwise false.
 */
export const isLoggedIn = () =>
  isBrowser() &&
  Boolean(LocalStorage.getSync("profile")) &&
  Boolean(LocalStorage.getSync("accessToken"))

/**
 * Tells service user is verifying their email with the given code.
 * @param {String} verificationCode
 */
export const verifyUser = async verificationCode =>
  api.put(`/profile/verification/${verificationCode}`)

/**
 * Requests service to send verification email
 * @param {String} username user will receive verification email
 */
export const sendVerification = async username =>
  api.post("/profile/verification", withJson({ username }))

/**
 * A Profile object used for updating and retrieving profiles
 * @typedef {Object<string, string>} Profile
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} email
 * @property {string} company
 * @property {string} versionKey
 */

/**
 * @param {Profile} updatedProfile
 */
export const updateProfile = async updatedProfile => {
  const responseModel = await api.put("/profile/me", withJson(updatedProfile))
  if (responseModel && responseModel.data && responseModel.data.developers) {
    const oldProfile = await LocalStorage.get("profile")
    const newProfile = { ...oldProfile, ...responseModel.data.developers }
    LocalStorage.put("profile", newProfile)
    return newProfile
  } else {
    throw new Error("response does not contain data.developers")
  }
}

/**
 * Requests the service to initiate a password reset
 * @param {String} username user that requests the password to be reset
 */
export const initiatePasswordReset = async username =>
  api.post("/profile/credentials/reset", withJson({ username }))

/**
 * Reset password
 * @param {String} resetToken Token used to identify the user
 * @param {String} newPassword new password
 */
export const resetPassword = async (resetToken, newPassword) =>
  api.put(`/profile/credentials/reset/${resetToken}`, withJson({ newPassword }))

/**
 * The object used for creating new accounts
 * @typedef {Object<string, string>} NewAccount  Profile
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} email
 * @property {string} company
 * @property {string} developerVersionKey
 * @property {string} password
 */

/**
 * Create Account
 * @param {NewAccount} newAccount
 */
export const createAccount = async newAccount =>
  api.post("/profile", withJson(newAccount))

/**
 *
 * @param {*} term
 * @see https://apig-marketplace-profile-sb.cfcdctest.kroger.com/swagger-ui.html#/ProfileAdmin/AdminListProfiles
 * @returns {Promise<void} will succeed or catch on failure
 */
export const getEmailSearchResults = async (term, nextPage = {}, sort = "") => {
  if (!term) {
    return { results: null, nextPage: nextPage }
  }
  const query = Object.entries(nextPage).reduce(
    (acc, [key, value]) => ({ [`page.${key}`]: value, ...acc }),
    {
      "filter.email.like": "%" + term + "%",
    }
  )

  const queryString = new URLSearchParams(query).toString()
  let requestUrl = "/admin/profile?" + queryString

  if (sort) {
    requestUrl = requestUrl + "&sort=email[" + sort + "]"
  }

  const response = await api.get(requestUrl)
  if (response && response.data && response.data.developers) {
    return {
      results: response.data.developers,
      nextPage: response.meta.nextPage,
    }
  }
  return { results: null, nextPage: nextPage }
}
